import {SocialNetworks, handleKeyPressEnterOrSpace} from '@wix/panda-js-utils'
import {SHARE_BUTTON_NAMES} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Facebook} from '../../../icons/Facebook'
import {Linkedin} from '../../../icons/Linkedin'
import {X} from '../../../icons/X'
import {getShareUrls} from '../../services/share'
import s from './social-bar.scss'

const DEFAULT_SPACING = 14

const REFERENCE_FONT_SIZE = 22

export interface SocialBarProps {
  t?: TFunction
  eventUrl?: string
  hideTitle?: boolean
  spacing?: number
  row?: boolean
  className?: string
  iconFontSize?: number
  eventId?: string
  onShare?: (eventId: string, buttonName: string) => void
  newColors?: boolean
}

export const SocialBar = ({
  t,
  hideTitle,
  spacing = DEFAULT_SPACING,
  row,
  className,
  iconFontSize,
  eventUrl,
  onShare,
  eventId,
  newColors = true,
}: SocialBarProps) => {
  const shareUrls = getShareUrls(eventUrl, t)

  const facebookShare = () => onShare && onShare(eventId, SHARE_BUTTON_NAMES.FACEBOOK)

  const xShare = () => onShare && onShare(eventId, SHARE_BUTTON_NAMES.X)

  const linkedInShare = () => onShare && onShare(eventId, SHARE_BUTTON_NAMES.LINKEDIN)

  return (
    <div data-hook="social-bar" className={classNames({[s.row]: row, [s.studioColor]: newColors}, className)}>
      {!hideTitle && <div className={row ? s.rowTitle : s.title}>{t('shareEventText')}</div>}
      <div className={s.icons}>
        <a
          target="_blank"
          href={shareUrls[SocialNetworks.Facebook]}
          className={s.icon}
          data-hook="social-bar-icon-facebook"
          onClick={facebookShare}
          onKeyPress={handleKeyPressEnterOrSpace(facebookShare)}
          tabIndex={0}
          aria-label={t('a11y.shareButtonLabel', {button: 'Facebook'})}
          rel="noreferrer"
        >
          <Facebook
            size={undefined}
            width={`${getIconSize(9, iconFontSize)}px`}
            height={`${getIconSize(16, iconFontSize)}px`}
          />
        </a>
        <a
          target="_blank"
          href={shareUrls[SocialNetworks.Twitter]}
          style={{margin: `0 ${spacing}px`}}
          className={s.icon}
          data-hook="social-bar-icon-x"
          onClick={xShare}
          onKeyPress={handleKeyPressEnterOrSpace(xShare)}
          tabIndex={0}
          aria-label={t('a11y.shareButtonLabel', {button: 'X'})}
          rel="noreferrer"
        >
          <X width={`${getIconSize(18, iconFontSize)}px`} height={`${getIconSize(14, iconFontSize)}px`} />
        </a>
        <a
          target="_blank"
          href={shareUrls[SocialNetworks.LinkedIn]}
          className={s.icon}
          data-hook="social-bar-icon-linkedin"
          onClick={linkedInShare}
          onKeyPress={handleKeyPressEnterOrSpace(linkedInShare)}
          tabIndex={0}
          aria-label={t('a11y.shareButtonLabel', {button: 'LinkedIn'})}
          rel="noreferrer"
        >
          <Linkedin
            size={undefined}
            width={`${getIconSize(19, iconFontSize)}px`}
            height={`${getIconSize(16, iconFontSize)}px`}
          />
        </a>
      </div>
    </div>
  )
}

const getIconSize = (defaultActualSize: number, requiredSize?: number) =>
  requiredSize ? Math.round(((defaultActualSize * requiredSize) / REFERENCE_FONT_SIZE) * 100) / 100 : defaultActualSize
