import {ExperimentNames, isEnded} from '@wix/wix-events-commons-statics'
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Animated} from '../../../../../../commons/components/animated'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import classesV2 from '../../classes-v2.scss'
import {Countdown} from '../../countdown'
import {AdditionalInfoFullDateLocation} from '../../full-date-location'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import {Date} from './date'
import {Description} from './description'
import s from './desktop-item.scss'
import {Image} from './image'
import {ItemProps, TitleComponentProps} from './interfaces'
import {Members} from './members'
import {Share} from './share'
import {Title} from './title'
import {TitleLocation} from './title-location'

export const DesktopItem = ({
  t,
  event,
  opened,
  hovered,
  showRibbon,
  showMembers,
  isSite,
  scheduleTbd,
  rtl,
}: ItemProps) => {
  const {
    allBreakpoints: {isListCountdownEnabled},
  } = useVisibilityStyles()
  const {isEditor} = useEnvironment()
  const {experiments} = useExperiments()

  const active = opened || hovered
  const eventEnded = isEnded(event)
  const widgetSpacingUpdatesEnabled = experiments.enabled(ExperimentNames.WidgetSpacingUpdates)

  return (
    <div className={classNames({[s.updatedSpacings]: widgetSpacingUpdatesEnabled})}>
      <div className={s.collapsedContainer}>
        <div className={s.leftPositioning}>
          <div className={s.left}>
            <div
              className={classNames(s.imageContainer, {
                [s.disableTransitions]: experiments.enabled(ExperimentNames.NewImageSettings) && isEditor,
                [s.collapsed]: active,
                [s.ltr]: !rtl,
              })}
            >
              <div className={s.image}>
                <Image event={event} />
              </div>
            </div>
            <div className={s.titleAndDateContainer}>
              {widgetSpacingUpdatesEnabled && showRibbon ? (
                <Ribbon event={event} className={classNames(s.ribbon, {[s.overrideMargin]: eventEnded})} />
              ) : null}
              {isListCountdownEnabled() && !eventEnded && (
                <div className={s.countdown}>
                  <Countdown event={event} />
                </div>
              )}
              <a tabIndex={0} aria-expanded={opened} role="button">
                {(widgetSpacingUpdatesEnabled || !active) && <Date scheduleTbd={scheduleTbd} event={event} />}
                <TitleComponent event={event} hovered={hovered} opened={opened} />
              </a>
            </div>
          </div>
        </div>
        <div className={widgetSpacingUpdatesEnabled ? null : s.button}>
          {!widgetSpacingUpdatesEnabled && showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
          <RsvpButton event={event} minWidth={100} maxWidth={160} />
        </div>
      </div>
      <div className={s.additionalInfo}>
        <Animated disabled={!isSite} height={calculateContentHeight} show={opened}>
          <div className={classNames(s.textContent, s.listContentText)}>
            <AdditionalInfoFullDateLocation
              event={event}
              dateClassName={classesV2.hoverEventDate}
              locationClassName={classesV2.hoverEventLocation}
            />
            {!widgetSpacingUpdatesEnabled && <Members event={event} visible={showMembers} />}
            <Description event={event} />
            {widgetSpacingUpdatesEnabled && <Members event={event} visible={showMembers} />}
            <Share t={t} event={event} />
          </div>
        </Animated>
      </div>
    </div>
  )
}

const TitleComponent = ({event, hovered, opened}: TitleComponentProps) =>
  hovered || opened ? <Title event={event} opened={opened} withOverflow={!opened} /> : <TitleLocation event={event} />

const calculateContentHeight = (element: HTMLElement) => element.offsetHeight + 60
